import { RetunBag } from '../pages/ReturnBag';
import { Home } from '../pages/Home';
import {Outlet, createBrowserRouter } from 'react-router-dom';

function Root () {
    return <Outlet/>

}

const Router = createBrowserRouter([
{
    path: '/',
    element: <Root />,
    children: [
    {
        path: ':parcelId',
        element: <Home />
    },
    {
        path: 'return/bag',
        element: <RetunBag />,
    },
    {
        path: '/',
        element: <RetunBag />,
    }
    ],
}
]);

export default Router