import React from 'react';
import { ReactComponent as LogoMP} from '../assets/MOBIUSPACK.svg';
import { ReactComponent as CrossIcon} from '../assets/x-symbole.svg';


const Logo = ({parcel = {}}) => {

    const style = {
        logo: {
            margin: "0"
        },
        img: {
            maxWidth:"12em",
            margin: "1.2em 0 1.2em 0"
          },
          icon: {
            maxHeight:"1.2em"
          }
    };

    const knownLocation = parcel.lastLocation === "" ||  parcel.lastLocation === undefined ? false : true
    const url = `https://imagedelivery.net/f6oJQiHR-JxRghI7cHVaoQ/${parcel.lastLocation}/public`

    return (
      <div className="logo container">
        { knownLocation
        ? (<div>
            <div  className="row justify-content-center"  style={style.logo}>
              <img className="align-self-center" alt="" src={url} style={style.img}/>
            </div>
            <div  className="row justify-content-center">
                <CrossIcon className="align-self-center" style={style.icon}/>
            </div>
        </div>)
        : ''
        }
        <div  className="row justify-content-center"  style={style.logo}>
            <LogoMP className="align-self-center" style={style.img}/>
        </div>
      </div>
    );
};

export default Logo;