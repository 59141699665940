import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../components/Footer';
import Header from '../components/Header';
import ParcelInfo from '../components/PacrcelInfo';
import KnowMore from '../components/KnowMore';
import EmailForm from '../components/EmailForm';
import { getParcelInfo }  from '../services/index.js';

export function Home (){

  let { parcelId } = useParams();

  const [parcel, setParcel] = useState({});

  useEffect(() => {

    const datafetch = async () => {
        const data = await getParcelInfo(parcelId);
        process.env.REACT_APP_DEBUG === true && console.log(data)
        
        setParcel(data.data);
    }
    datafetch();
  }, [parcelId])

  const style = {
    home : {
        backgroundColor: '#1E1E1E',
        height: 'auto',
        width: '100%',
        margin: '0',
        padding: '0',
      }
  }

  return <div className="home" style={style.home}>
    <Header parcel={parcel} />
    <ParcelInfo parcel={parcel} />
    <KnowMore parcel={parcel} />
    <EmailForm />
    < Footer marginTop={'15%'}/>
  </div>
}
